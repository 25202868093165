
export default class CluarCustomData {
    constructor(data) {
        this.data = data;
    }
    
    budgetRequestTransportations() {
        return this.data["budget-request"]['transportations'];
    }

    budgetRequestIncoterms() {
        return this.data["budget-request"]['incoterms'];
    }
}
