import React, { useState } from 'react';

import { Row, Col } from 'antd';

import Form from './Form';

import Cluar from '../../../Cluar';

import './index.less';

export default ({ title, content }) => {
    const [ formActive, setFormActive ] = useState('');
    const [ form, setForm ] = useState(null);
    const activeFormSelector = (formName) => {
        setFormActive(formName);
        if (formName == 'air') {
            setForm(<Form transportation="air" />);
        } else if (formName == 'sea') {
            setForm(<Form transportation="sea" />);
        } else if (formName == 'land') {
            setForm(<Form transportation="land"/>);
        }
    };
    const formSelectorActiveClass = 'budget-request__form-selector--active';
    return (
        <section className="budget-request">
          <div className="budget-request__intro">
            <h1>{title}</h1>
            <div dangerouslySetInnerHTML={{__html: content}}></div>
          </div>
          <div className={ form == null ? 'budget-request__form-selector__container' : 'budget-request__form-selector__container--actived' }>
            <Row>
              <Col span={6}>
                <div className={`budget-request__form-selector ${formActive === 'air' ? formSelectorActiveClass : ''}`} onClick={ () => activeFormSelector('air') }>
                  <img src="/images/budget-request-air.svg"/>
                  <h3>{Cluar.plainDictionary('budget-air')}</h3>
                </div>
              </Col>
              <Col offset={3} span={6}>
                <div className={`budget-request__form-selector ${formActive === 'sea' ? formSelectorActiveClass : ''}`} onClick={ () => activeFormSelector('sea') }>
                  <img src="/images/budget-request-sea.svg"/>
                  <h3>{Cluar.plainDictionary('budget-sea')}</h3>
                </div>
              </Col>
              <Col offset={3} span={6}>
                <div className={`budget-request__form-selector ${formActive === 'land' ? formSelectorActiveClass : ''}`} onClick={ () => activeFormSelector('land') }>
                  <img src="/images/budget-request-land.svg"/>
                  <h3>{Cluar.plainDictionary('budget-road')}</h3>
                </div>
              </Col>
            </Row>
          </div>
          { form }
        </section>
    );
};
