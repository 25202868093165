import React from 'react';
import Cluar from './../../Cluar.js';
import { Button } from 'antd';
import { useHistory } from "react-router-dom";

import './index.less';

export default () => {

    const history = useHistory();

    function handleClick() {
        history.push("/");
    }

    return (
        <main className="page--notfound">
            <h1>{Cluar.dictionary('404-title')}</h1>
            <p>{Cluar.dictionary('404-subtitle')}</p>
            <Button type="primary" onClick={handleClick}>{Cluar.dictionary('404-button')}</Button>
        </main>
    );
};
