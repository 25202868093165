import React, { useState, useEffect } from 'react';
import { Switch, DatePicker, Select, Divider, Row, Col, Form, Input, Radio, InputNumber, Button, Space, notification, Checkbox, Upload } from 'antd';
import { MinusCircleTwoTone, PlusOutlined, InboxOutlined } from '@ant-design/icons';

import Cluar from '../../../../Cluar';

import './index.less';

const { Dragger } = Upload;

const validateFile = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

const layout = {
    rowGutter: { gutter: [50, 0] },
    spanCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 12 } },
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
    labelAlign: "left"
};

export default ({transportation}) => {

    const [ submit , setSubmit ] = useState(false);
    const [isCompany, setIsCompany] = useState(true);
    const [shipmentDangerousValue, setShipmentDangerousValue] = useState(0);
    const [ loading, setLoading ] = useState(false);

    const { Option } = Select;
    const { TextArea } = Input;

    const onFinish = values => {
        values.locale = window.localStorage.getItem('locale');
        if (!values.goods.list || values.goods.list.length <= 0)
        {
            notification.error({
            message: Cluar.plainDictionary('budget-request-message'),
            description: Cluar.plainDictionary('budget-error-goods'),
            top: 100
            });
            return;
        }
        console.log('Success:', values);
        setLoading(true);
        const fail = () => {
            setLoading(false);
            notification.error({
            message: Cluar.plainDictionary('budget-request-message'),
            description: Cluar.plainDictionary('budget-failed'),
            top: 100
            });
        }
        const success = () => {
            notification.success({
                message: Cluar.plainDictionary('budget-request-message'),
                description: Cluar.plainDictionary('budget-success'),
                top: 100
            });
        }
        values.transportation = transportation;
        const file = values.file;
        delete values.file;
        Cluar.api('budget-request', {
            data: values,
            onSuccess: (data) => {
                if (data.json && data.json.result === true) {
                    success();
                    setSubmit(true);
                    if (typeof file != 'undefined') {
                        if(typeof file.fileList[0] != 'undefined') {
                          const formData = new FormData();
                          formData.append("uid", data.json.uid);
                          formData.append("file", file.fileList[0].originFileObj);
                          Cluar.api('budget-request-upload', {
                            data: formData,
                            onSuccess: (data) => {
                                if (data.json && data.json.result === true) {
                                  console.log('File uploaded with success');
                                } else {
                                    setLoading(false);
                                    notification.error({
                                        message: Cluar.plainDictionary('budget-request-message'),
                                        description: Cluar.plainDictionary('budget-failed-upload'),
                                        top: 100
                                    });
                                }
                            },
                            onFail: (data) => {
                              console.log("ContactForm.Upload failed:", data);
                              fail();
                            }
                          });
                        }
                      }
                      setLoading(false);
                } else {
                    fail();
                }
            },
            onFail: (data) => {
                console.log("BudgetRequestForm failed:", data);
                fail();
            }
        });
    };

    const validateMessages = {
        required: '${label} ' + Cluar.plainDictionary('budget-validate'),
        types: {
            email: '${label} ' + Cluar.plainDictionary('budget-validate-email'),
            number: '${label} ' + Cluar.plainDictionary('budget-validate-number'),
        }
    };

    return (
        <Form {...layout} name="nest-messages" validateMessages={validateMessages} onFinish={onFinish}>

            <h2>{Cluar.plainDictionary('budget-id')}</h2>
            <Divider />
            <Row {...layout.rowGutter} style={{ marginBottom: "24px" }}>
                <Col span={24}>
                    <Switch onChange={(checked) => setIsCompany(checked)} unCheckedChildren={Cluar.plainDictionary('budget-radio-individual')} checkedChildren={Cluar.plainDictionary('budget-radio-company')} defaultChecked/>
                </Col>
            </Row>
            <Row {...layout.rowGutter}>
                <Col {...layout.spanCol}>
                    <Form.Item name={['client', 'name']} label={Cluar.plainDictionary('budget-name')} rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                </Col>
                {isCompany ?
                    <Col {...layout.spanCol}>
                        <Form.Item name={['client', 'company']} label={Cluar.plainDictionary('budget-company')} rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    :
                    null}
            </Row>
            <Row {...layout.rowGutter}>
                <Col {...layout.spanCol}>
                    <Form.Item name={['client', 'email']} label={Cluar.plainDictionary('budget-mail')} rules={[{ required: true, type: 'email' }]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col {...layout.spanCol}>
                    <Form.Item name={['client', 'phone']} label={Cluar.plainDictionary('budget-phone')} rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>

            <h2>{Cluar.plainDictionary('budget-details')}</h2>
            <Divider />
            <Row {...layout.rowGutter}>
                <Col {...layout.spanCol}>
                    <Form.Item name={['shipment', 'origin']} label={Cluar.plainDictionary('budget-origin')} rules={[{ required: true }]}>
                        <TextArea placeholder={"(" + Cluar.plainDictionary('budget-placeholder-originDestination') + ")"} autoSize={{ minRows: 3 }} />
                    </Form.Item>
                </Col>
                <Col {...layout.spanCol}>
                    <Form.Item name={['shipment', 'destination']} label={Cluar.plainDictionary('budget-destination')} rules={[{ required: true }]}>
                        <TextArea placeholder={"(" + Cluar.plainDictionary('budget-placeholder-originDestination') + ")"} autoSize={{ minRows: 3 }} />
                    </Form.Item>
                </Col>
            </Row>
            <Row {...layout.rowGutter}>
                <Col span={24}>
                    <Form.Item name={['shipment', 'incoterms']} label={Cluar.plainDictionary('budget-incoterms')} rules={[{ required: true }]}>
                        <Select placeholder={Cluar.plainDictionary('budget-placeholder-incoterms')}>
                          {
                              Cluar.customData().budgetRequestIncoterms().map(
                                  (i)=>
                                      <Option value={i.code}>{i.description}</Option>
                              )
                          }
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <h2>{Cluar.plainDictionary('budget-goods')}</h2>
            <Divider />
            <Form.List name={["goods", "list"]}>
                {(fields, { add, remove }) => {
                    return (
                        <div>
                            {fields.map(field => (
                                <Row className="package-line" {...layout.rowGutter}>
                                    <Col sm={24} md={7}>
                                        <Form.Item
                                          {...field}
                                          name={[field.name, 'weight']}
                                          fieldKey={[field.fieldKey, 'weight']}
                                          label={Cluar.plainDictionary('budget-weight')}
                                          rules={[{ required: true }]}>
                                          <Input placeholder={Cluar.plainDictionary('budget-weight')} />
                                        </Form.Item>
                                    </Col>
                                    <Col sm={24} md={14}>
                                        <Form.Item
                                          {...field}
                                          name={[field.name, 'volume']}
                                          fieldKey={[field.fieldKey, 'volume']}
                                          label={Cluar.plainDictionary('budget-volume')}
                                          rules={[{ required: true }]}>
                                          <Input placeholder={Cluar.plainDictionary('budget-placeholder-volume')} />
                                        </Form.Item>
                                    </Col>
                                    <Col sm={24} md={3}>
                                        <MinusCircleTwoTone twoToneColor="#ff4d4f" onClick={() => { remove(field.name); }} />
                                    </Col>
                                </Row>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => { add(); }} block >
                                    <PlusOutlined /> {Cluar.plainDictionary('budget-package')}
                                </Button>
                            </Form.Item>
                        </div>
                    );
                }}
            </Form.List>
            <Row {...layout.rowGutter}>
                <Col span={24}>
                    <Form.Item label={Cluar.plainDictionary('budget-description')} name={['goods', 'description']} rules={[{ required: true }]}>
                        <TextArea placeholder={Cluar.plainDictionary('budget-placeholder-description')} autoSize={{ minRows: 5 }} />
                    </Form.Item>
                </Col>
            </Row>
            <Row {...layout.rowGutter}>
                <Col span={24}>
                    <Form.Item label={Cluar.plainDictionary('budget-file')} name={['file']}>
                        <Dragger customRequest={validateFile} multiple={false}>
                            <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">{Cluar.plainDictionary('budget-file-text')}</p>
                            <p className="ant-upload-hint">{Cluar.plainDictionary('budget-file-placeholder')}</p>
                        </Dragger>
                    </Form.Item>
                </Col>
            </Row>
            <Row {...layout.rowGutter}>
                <Col span={24}>
                    <Form.Item name={['goods', 'value']} label={Cluar.plainDictionary('budget-value')}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row {...layout.rowGutter} style={{ marginBottom: "24px" }}>
                <Col span={24}>
                    <span>{Cluar.plainDictionary('budget-hazardous')}? &nbsp;&nbsp;&nbsp;</span>
                    <Checkbox name={['goods', 'hazardous']} onChange={(checked) => setShipmentDangerousValue(checked)}>{Cluar.plainDictionary('budget-radio-yes')}</Checkbox>
                </Col>
            </Row>
            <Row {...layout.rowGutter}>
                <Col span={24}>
                    <Form.Item name={['goods', 'observations']} label={Cluar.plainDictionary('budget-observations')} >
                        <TextArea placeholder={Cluar.plainDictionary('budget-placeholder-observation')} autoSize={{ minRows: 5 }} />
                    </Form.Item>
                </Col>
            </Row>
            {!submit ? 
            <Row {...layout.rowGutter}>
                <Col span={24}>
                    <Form.Item wrapperCol={24}>
                        <Button htmlType="submit" type="primary" block loading={loading}>{Cluar.plainDictionary('budget-button')}</Button>
                    </Form.Item>
                </Col>
            </Row>
            :
            null }
        </Form >
    );
}
