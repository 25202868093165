import React, { useState, useRef, useEffect, useCallback } from 'react';
import ReactDOM from "react-dom";

import Burger from '@animated-burgers/burger-slip';
import '@animated-burgers/burger-slip/dist/styles.css';

import classNames from 'classnames';

import { Layout, Menu, Row, Col } from 'antd';

import { GlobalOutlined, PhoneOutlined, HomeOutlined, MailOutlined, LinkedinOutlined } from '@ant-design/icons';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation
} from "react-router-dom";

import Analytics from './Analytics.js';

import Cluar from './Cluar.js';

import Builder from './Builder.js';

import NotFound from './pages/NotFound';
import Dictionary from './components/Dictionary';
import Cookies from './components/Cookies';

import './App.less';

const { Header, Content, Footer } = Layout;
const { SubMenu } = Menu;

export default () => {
  const defaultLanguage = Cluar.defaultLanguage();

  const storageLocale = window.localStorage.getItem('locale');
  if (storageLocale == null) {
    window.localStorage.setItem('locale', Cluar.currentLanguage().locale);
  } else {
    Cluar.changeLanguage(storageLocale);
  }

  const [burgerMenu, setBurgerMenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState('main');
  const [locale, setLocale] = useState(Cluar.currentLanguage().locale);

  const handleMenuClick = (selectMenu) => {
    setBurgerMenu(false);
    if (selectMenu) {
      setActiveMenu(selectMenu);
    }
    window.scrollTo(0, 0);
  };

  const menuLanguages = [];
  const menu = [];
  const subMenuKeys = [];
  const routes = [];
  for (const language of Cluar.languages()) {
    if (!Cluar.pages()[language.code]) {
      continue;
    }
    if (language.code !== Cluar.currentLanguage().code) {
      menuLanguages.push(
        <Menu.Item key={language.code} onClick={() => {
          Cluar.changeLanguage(language.locale);
          window.localStorage.setItem('locale', Cluar.currentLanguage().locale);
          window.location.href = `/${language.locale}/`;
        }}>{language.description}</Menu.Item>
      );
    }

    const buildMenu = (page) => {
      if (page.menu && language.code === Cluar.currentLanguage().code) {
        const key = `${page.link}`;
        if (Cluar.pages()[language.code].find((p) => p.menu && p.parent == page.link)) {
          subMenuKeys.push(key);
          return (
            <SubMenu key={key} title={
              <Link to={`/${Cluar.currentLanguage().locale}${page.link}`} onClick={() => handleMenuClick(key)}>
                {page.menu_title && page.menu_title != '' ? page.menu_title : page.title}
              </Link>
            }>
              {Cluar.pages()[language.code].filter((p) => p.menu && p.parent == page.link).map((p) => buildMenu(p))}
            </SubMenu>
          );
        } else {
          return (
            <Menu.Item key={key}>
              <Link to={`/${Cluar.currentLanguage().locale}${page.link}`} onClick={() => handleMenuClick(key)}>
                {page.menu_title && page.menu_title != '' ? page.menu_title : page.title}
              </Link>
            </Menu.Item>
          );
        }
      }
      return null;
    };

    const subroutes = [];
    for (const page of Cluar.pages()[language.code]) {
      if (page.menu && page.parent == "" && language.code === Cluar.currentLanguage().code) {
        menu.push(
          buildMenu(page)
        );
      }
      subroutes.push(
        <Route key={`/${language.locale}${page.link}`} path={`/${language.locale}${page.link}`} exact render={(propRouter) => {
          return <Builder page={page} />;
        }} />
      );
    }
    /*subroutes.push(
      <Route render={(propRouter) => propRouter.match.isExact || propRouter.location.key ? null : <NotFound />} />
    );*/
    routes.push(
      <Route key={`/${language.locale}/`} path={`/${language.locale}/`}>
        <Switch>
          {subroutes}
          <Route component={NotFound} />
        </Switch>
      </Route>
    );
  }

  useEffect(() => {
  }, []);

  return (
    <Router>
      {Analytics.init() && <Analytics.RouteTracker />}
      <div className="page">
        <Layout /*style={{ marginBottom: height }}*/>
          <Header className={classNames({ 'header-burger-open': burgerMenu })}>
            <div className="logo">
              <Link to={`/${Cluar.currentLanguage().locale}/`} onClick={() => handleMenuClick('/')}>
                <img alt="LusoPanarcos Logo" title="LusoPanarcos" src="/images/logo.svg" />
              </Link>
            </div>
            <div className={
              classNames({
                'menu': true
              })
            }>
              <Menu
                theme="light"
                mode="horizontal"
                defaultSelectedKeys={[activeMenu]}
                selectedKeys={[activeMenu]}>
                {menu}
              </Menu>
            </div>
            <div className={
              classNames({
                'menu': true,
                'menu-burger': true,
                'menu-burger-open': burgerMenu
              })
            }>
              <Menu
                theme="light"
                mode="inline"
                defaultSelectedKeys={[activeMenu]}
                selectedKeys={[activeMenu]}
                openKeys={subMenuKeys}>
                {menu}
              </Menu>
            </div>
            <div className="menu-burger-button">
              <Burger isOpen={burgerMenu} onClick={() => { setBurgerMenu(!burgerMenu); }} />
            </div>
            <Menu
              theme="light"
              className="menu-languages"
              mode={'horizontal'}
              defaultSelectedKeys={[activeMenu]}
              selectedKeys={[activeMenu]}>
              <SubMenu icon={<GlobalOutlined />} title={Cluar.currentLanguage().code}>
                {menuLanguages}
              </SubMenu>
            </Menu>
          </Header>
          <Content>
            <Switch>
              <Route path="/" exact render={(propRouter) => {
                return <Redirect to={`/${Cluar.currentLanguage().locale}/`} />;
              }} />
              {routes}
              <Route component={NotFound} />
            </Switch>
          </Content>
          <Footer /*ref={refFooter}*/>
            <Row align="middle" gutter={[0, 10]}>
              <Col xs={24} lg={8}>
                <div className="logo" data-sal="slide-up" data-sal-duration="2000" data-sal-easing="ease-out-cubic">
                  <img src="/images/logo.svg" />
                  <p>&copy; 2020 - LusoPanarcos</p>
                </div>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 8, offset: 8 }}>
                <Row>
                  <Col><HomeOutlined /></Col>
                  <Col>
                    <address>&nbsp;Rua Ant&oacute;nio Nobre, Nr. 1D - 3&ordm; Esq<br />
                      &nbsp;2800-260 Almada,<br /> &nbsp;Portugal
                    </address></Col>
                </Row>
                <Row>
                  <Col><PhoneOutlined /></Col>
                  <Col>
                    <p>&nbsp;+351 210 128 830<br />
                      &nbsp;{locale === "pt" ? "(Chamada para a rede fixa nacional)" : "(Call to National Landline network)"}</p>
                  </Col>
                </Row>
                <Row>
                  <p><MailOutlined /><a href="mailto:info@lusopanarcos.pt"> info@lusopanarcos.pt</a></p>
                </Row>
                <Row>
                  <p><LinkedinOutlined /><a href="https://www.linkedin.com/company/lusopanarcos"> LinkedIn</a></p>
                </Row>
              </Col>
            </Row>
            <Row justify="center" className="logos" align="middle" gutter={[0, 10]}>
              <Col xs={12} lg={4}>
                <a href="/files/pt/Alvara-LusoPanarcos.pdf"><img src="/images/logos_footer/imt.png" /></a>
              </Col>
              <Col xs={12} lg={3}>
                <a href="https://www.apat.pt/pt/associados_detalhe/222/lusopanarcos-internacional-unipessoal-lda"><img src="/images/logos_footer/apatSocio.png" /></a>
              </Col>
              <Col xs={8} lg={2}>
                <img src="/images/logos_footer/seguroResponsabilidadeCivilAXA.png" />
              </Col>
              <Col xs={8} lg={3}>
                <a href="https://www.livroreclamacoes.pt/inicio"><img src="/images/logos_footer/livroReclamacoes.png" /></a>
              </Col>
              <Col xs={8} lg={2}>
                <a href="https://www.apat.pt/pt/selo_excelencia"><img src="/images/logos_footer/apatExcelencia.png" /></a>
              </Col>
              <Col xs={8} lg={2}>
                <a href="https://amchamportugal.pt/"><img src="/images/logos_footer/AMCham_portugal.jpg" /></a>
              </Col>
              <Col xs={8} lg={3}>
                <a href="https://www.jctrans.net/shophome/index-112988.html"><img src="/images/logos_footer/jctrans.png" /></a>
              </Col>
            </Row>
            <Row className="legal" justify="center" align="middle" gutter={[0, 10]}>
              <Col>
                <a href={`/${Cluar.currentLanguage().locale}${Cluar.currentLanguage().locale == 'pt' ? '/politica-privacidade' : Cluar.currentLanguage().locale == 'en' ? '/privacy-policy' : null}`}>{Cluar.plainDictionary('cookies-link')}</a>
              </Col>
            </Row>
          </Footer>
          <Cookies />
        </Layout>
      </div>
    </Router>
  );
}
