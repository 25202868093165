import React, { useState } from 'react';

import { Row, Col, Form, Input, Button, notification, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import Cluar from '../../../Cluar';

import './index.less';
import FormItem from 'antd/lib/form/FormItem';

const { TextArea } = Input;

const validateFile = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

const validateMessages = {
    required: '${label} é de preenchimento obrigatório!',
    types: {
        email: '${label} não é um email válido!'
    }
};

const layout = {
    rowGutter: { gutter: [25, 0] },
    labelCol: {span: 'hide'}
};

export default ({ title }) => {
    const [ submit , setSubmit ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const handleFinish = (values)=> {
      values.contactForm.locale = window.localStorage.getItem('locale');
        setLoading(true);
        const fail = () => {
            setLoading(false);
            notification.error({
                message: title ,
                description: Cluar.dictionary('contact-fail'),
                top: 100
            });
        };
        const success = () => {
          notification.success({
              message: title ,
              description: Cluar.dictionary('contact-success'),
              top: 100
          });
        };
        const file = values.contactForm.file;
        delete values.contactForm.file;
        Cluar.api('contact', {
            data: values.contactForm,
            onSuccess: (data) => {
                if (data.json && data.json.result === true) {
                  success();
                  setSubmit(true);
                  if (typeof file != 'undefined') {
                    if(typeof file.fileList[0] != 'undefined') {
                      const formData = new FormData();
                      formData.append("uid", data.json.uid);
                      formData.append("file", file.fileList[0].originFileObj);
                      Cluar.api('contact-upload', {
                        data: formData,
                        onSuccess: (data) => {
                            if (data.json && data.json.result === true) {
                              console.log('File uploaded with success');
                            } else {
                                setLoading(false);
                                notification.error({
                                    message: title ,
                                    description: Cluar.dictionary('contact-fail-upload'),
                                    top: 100
                                });
                            }
                        },
                        onFail: (data) => {
                          console.log("ContactForm.Upload failed:", data);
                          fail();
                        }
                      });
                    }
                  }
                  setLoading(false); 
          } else {
              fail();
          }
        },
            onFail: (data) => {
                console.log("ContactForm failed:", data);
                fail();
            }
        });
    };
    return (
        <section className="contact-form">
          <hr/>
          <Form {...layout} validateMessages={validateMessages} onFinish={handleFinish}>
            <h2>{title}</h2>
            <Row {...layout.rowGutter}>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Form.Item {...layout.labelCol} label={Cluar.plainDictionary('contact-name')} name={['contactForm', 'name']} rules={[{ required: true }]}>
                  <Input placeholder={Cluar.plainDictionary('contact-name')} />
                </Form.Item>
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <Form.Item {...layout.labelCol} label="E-mail" name={['contactForm', 'email']} rules={[{ required: true, type: 'email' }]}>
                  <Input placeholder="E-mail" />
                </Form.Item>
              </Col>
            </Row>
            <Row {...layout.rowGutter}>
              <Col span={24}>
                <Form.Item {...layout.labelCol} label={Cluar.plainDictionary('contact-subject')} name={['contactForm', 'subject']} rules={[{ required: true }]}>
                  <Input placeholder={Cluar.plainDictionary('contact-subject')} />
                </Form.Item>
              </Col>
            </Row>
            <Row {...layout.rowGutter}>
              <Col span={24}>
                <Form.Item {...layout.labelCol} label={Cluar.plainDictionary('contact-message')} name={['contactForm', 'message']} rules={[{ required: true }]} >
                  <TextArea autoSize={{ minRows: 3 }} placeholder={Cluar.plainDictionary('contact-message')} />
                </Form.Item>
              </Col>
            </Row>
            <Row {...layout.rowGutter}>
              <Col span={24}>
                <Form.Item {...layout.labelCol} label={Cluar.plainDictionary('contact-file')} name={['contactForm', 'file']} >
                  <Upload customRequest={validateFile} multiple={false} >
                    <Button icon={<UploadOutlined />}>{Cluar.plainDictionary('contact-file-placeholder')}</Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
          {!submit ? 
            <Row {...layout.rowGutter}>
              <Col span={24}>
                <Form.Item wrapperCol={24}>
                  <Button htmlType="submit" type="primary" block {...{loading}}>{Cluar.plainDictionary('contact-send')}</Button>
                </Form.Item>
              </Col>
            </Row>
            :
            null }
          </Form>
        </section>
    );
};
